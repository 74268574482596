import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Beaver Jam",
      metaTags: [
        {
          name: "description",
          content: "This is the landing page for the mobile app, Beaver Jam."
        },
        {
          property: "keywords",
          content: "ben schreiber, beaver, beaver jam, beaverjam, ios, corona sdk, solar2d"
        }
      ]
    }
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () =>
      import(/* webpackChunkName: "feedback" */ "../views/Feedback.vue"),
    meta: {
      title: "Feedback | Beaver Jam",
      metaTags: [
        {
          name: "description",
          content:
            "Beaver Jam Feedback Form."
        },
        {
          property: "keywords",
          content: "ben schreiber, beaver, beaver jam, beaverjam, ios, corona sdk, solar2d, about, about beaver jam, about beaverjam, autodesk maya, texture packer, marmoset hexels, feedback, bug"
        }
      ]
    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "About | Beaver Jam",
      metaTags: [
        {
          name: "description",
          content:
            "Welcome to the Beaver Jam info page! Here you will find details about the design process, tools used, and developer notes."
        },
        {
          property: "keywords",
          content: "ben schreiber, beaver, beaver jam, beaverjam, ios, corona sdk, solar2d, about, about beaver jam, about beaverjam, autodesk maya, texture packer, marmoset hexels"
        }
      ]
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
    meta: {
      title: "Privacy Policy | Beaver Jam",
      metaTags: [
        {
          name: "description",
          content:
            "Privacy Policy for the mobile app, Beaver Jam."
        },
        {
          property: "keywords",
          content: "ben schreiber, beaver, beaver jam, beaverjam, ios, corona sdk, solar2d, privacy, privacy policy"
        }
      ]
    }
  },
  {
    path: "/soundtrack",
    name: "Soundtrack",
    component: () =>
      import(/* webpackChunkName: "soundtrack" */ "../views/Soundtrack.vue"),
    meta: {
      title: "Soundtrack | Beaver Jam",
      metaTags: [
        {
          name: "description",
          content:
            "This is the soundtrack for the mobile game, Beaver Jam."
        },
        {
          property: "keywords",
          content: "ben schreiber, beaver, beaver jam, beaverjam, ios, corona sdk, solar2d, soundtrack, beaverjam soundtrack, beaver jam soundtrack, wild horses, jacqui's tune, ben there done that, splitwise, mooning at the howl, death music, golden road"
        }
      ]
    }
  },
  {
    path: "/bug",
    redirect: { name: "Feedback" }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
