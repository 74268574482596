<template lang="pug">
  .footer.fixed-bottom
    p
      span.d-none.d-sm-inline © Copyright 2022 Ben Schreiber
      b-icon-dot.mx-1.mx-sm-2.mx-md-3.d-none.d-sm-inline
      b-link(@click="modalShow = !modalShow") Contact
      b-icon-dot.mx-1.mx-sm-2.mx-md-3
      b-link(href="/feedback") Feedback
      b-icon-dot.mx-1.mx-sm-2.mx-md-3
      b-link(href="https://paypal.me/BenSchreiber/", target="_blank") Donate
      br.d-sm-none
      span.d-sm-none © Copyright 2022 Ben Schreiber
    b-modal#contact-modal(v-model="modalShow", :header-bg-variant="bgVariant", :body-bg-variant="bgVariant", :footer-bg-variant="bgVariant", centered)
      div#contact-form-wrapper
        b-form.needs-validation#contact-form(@submit='onSubmit', @reset='onReset', @cancel='onCancel', v-bind:class="sendStatus", novalidate)
          b-input-group.mb-3.mr-sm-2(label='Email:', label-for='input-1', prepend="Email")
            b-form-input#input-1(v-model='form.email', type='email', placeholder='Your email address', required)
            b-form-invalid-feedback Email address is invalid.
          b-input-group.mb-3.mr-sm-2(label='Name:', label-for='input-2', prepend="Name")
            b-form-input#input-2(v-model='form.name', placeholder='Your name', required)
            b-form-invalid-feedback Name is required.
          b-input-group.mb-3(label='Message:', label-for='input-3')
            b-form-textarea#input-3(v-model='form.message', placeholder='Your message', rows="4", required)
            b-form-invalid-feedback Message is required.
          #contact-form-buttons
            b-button(@click="onCancel", :disabled.sync="sending") Cancel
            b-button.ml-2(type='reset', :disabled.sync="sending") Reset
            b-button.ml-2(type='submit', variant='info', :disabled.sync="sending") Submit
        p#contact-success(v-bind:class="sendStatus") Message Sent!
        p#contact-failure(v-bind:class="sendStatus") Oops, something went wrong. Please try again later.
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      form: {
        email: "",
        name: "",
        message: ""
      },
      bgVariant: "light",
      modalShow: false,
      sendStatus: "",
      sendDetails: "",
      sending: false
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      var form = document.getElementById("contact-form");
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.sending = true;
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_MAILGUNURL,
          auth: {
            username: "api",
            password: process.env.VUE_APP_MAILGUNAPIKEY
          },
          params: {
            from: "Beaverjam.app <app@beaverjam.app>",
            to: "ben.r.schreiber@gmail.com",
            subject:
              "Message from: " + this.form.name + " <" + this.form.email + ">",
            text: this.form.message
          }
        }).then(
          response => {
            this.sendStatus = "sent";
            this.sendDetails = response;
            setTimeout(() => {
              this.onCancel();
            }, 2000);
          },
          reject => {
            this.sendStatus = "fail";
            this.sendDetails = reject;
            setTimeout(() => {
              this.onCancel();
            }, 2000);
          }
        );
      }
      form.classList.add("was-validated");
    },
    onReset(event) {
      event.preventDefault();
      var form = document.getElementById("contact-form");
      this.form.email = "";
      this.form.name = "";
      this.form.message = "";
      form.classList.remove("was-validated");
    },
    onCancel() {
      var form = document.getElementById("contact-form");
      this.form.email = "";
      this.form.name = "";
      this.form.message = "";
      form.classList.remove("was-validated");
      this.modalShow = false;
      setTimeout(() => {
        this.sendStatus = "";
        this.sending = false;
      }, 1000);
    }
  }
};
</script>

<style scoped lang="less">
p {
  color: var(--faded-color);
}
#contact-form-wrapper {
  position: relative;
  overflow: hidden;
  #contact-form {
    transition: all 0.5s ease;
    opacity: 1;
    &.sent,
    &.fail {
      transform: translateX(-100%);
      opacity: 0;
    }
    #contact-form-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
  #contact-success,
  #contact-failure {
    transition: opacity 0.5s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    opacity: 0;
    text-align: center;
    z-index: -1;
  }
  #contact-success {
    &.sent {
      opacity: 1;
      z-index: 1;
    }
  }
  #contact-failure {
    &.fail {
      opacity: 1;
      z-index: 1;
    }
  }
}
</style>
