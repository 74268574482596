import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import "./plugins/bootstrap-vue";
import { BootstrapVueIcons } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import VueAnalytics from "vue-analytics";
import VueAxios from "./plugins/axios";
import VueCookie from "vue-cookie";

Vue.use(VueAnalytics, {
  id: "UA-223074634-1",
  router
});

Vue.use(VueAxios);

Vue.config.productionTip = false;

Vue.use(BootstrapVueIcons);
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(VueCookie);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
