<template lang="pug">
  div
    .home
      .home-inner
        div.back-shadow
        img.home-image(alt="Beaver Jam Preview" src="../assets/images/beaverjam-preview.gif")
        Index(title="Beaver Jam" subtitle="Developed by Ben Schreiber")
        div.links
          b-link(href="https://apps.apple.com/us/app/beaver-jam/id1615219239", target="_blank", onclick="ga('send','event','downloads','click','apple', 0)")
            img.download-apple(alt="Download on the Apple Store" src="../assets/images/download-on-apple-store.png")
          b-link(href="https://play.google.com/store/apps/details?id=com.coronalabs.beaverjam", target="_blank", onclick="ga('send','event','downloads','click','google', 0)")
            img.download-google(alt="Get it on Google Play" src="../assets/images/download-on-play-store.png")
</template>

<script>
import Index from "@/components/Index.vue";

export default {
  name: "Home",
  components: {
    Index
  }
};
</script>

<style scoped lang="less">
.home {
  z-index: -1;
  .home-image, .back-shadow, .download-apple, .home-inner {
    transition: all 0.5s ease;
  }
  .home-inner {
    margin: 0 auto;
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    .links {
      width: 100%;
      float: left;
    }
  }
  .home-image {
    width: 600px;
    max-width: 98vw;
    height: auto;
  }
  .back-shadow {
    width: 560px;
    height: 280px;
    max-width: 80vw;
    max-height: 40vw;
    box-shadow: 20px 10px 60px black;
    position: absolute;
    margin: 0 auto;
    display: block;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    border-radius: 26px;
  }
  .download-apple, .download-google {
    max-width: 160px;
    margin: 20px auto;
  }
}
@media (orientation: landscape) {
  .hello {
    top: 30% !important;
  }
  .home-image {
    max-width: 60vw !important;
  }
  .back-shadow {
    max-width: 52vw !important;
    max-height: 26vw !important;
  }
}
</style>
