<template lang="pug">
  div#app
    b-container.mx-0.px-0#main(fluid)
      #router-content
        router-view
      app-header
      app-footer
</template>

<script>
//import your components here
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    "app-header": Header,
    "app-footer": Footer
  },
  mounted() {
    // document.documentElement.style.setProperty("--secondaryR",Math.floor(Math.random() * 255) + 1);
    // document.documentElement.style.setProperty("--secondaryG",Math.floor(Math.random() * 255) + 1);
    // document.documentElement.style.setProperty("--secondaryB",Math.floor(Math.random() * 255) + 1);
  }
};
</script>

<style lang="less">
@import "./assets/styles/fonts.css";
@import "./assets/styles/variables.less";
@import "./assets/styles/animations.css";
@import "./assets/styles/helpers.css";
@import "./assets/styles/custom.less";
@import "./assets/styles/mobile.less";
@import "./assets/styles/notouch.less";

html,
body,
#app,
#main {
  height: 100%;
  min-height: 100%;
  color: var(--primary-color);
  transition: all 0.5s ease;
  font-family: var(--paragraphs);
  overflow-x: hidden;
}

body {
  transition: all 0.5s ease;
  background-image: url("assets/images/web-background-003.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}

p,
th,
td {
  color: var(--primary-color);
  transition: all 0.5s ease;
}

a:link,
a:visited,
a:hover,
a:active {
  color: var(--primary-color);
}

#app {
  font-family: var(--paragraphs);
  letter-spacing: 0.05rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--primary-color);
  transition: all 0.5s ease;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--headings);
  }
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    background: linear-gradient(180deg, rgba(var(--secondaryR),var(--secondaryG),var(--secondaryB),1) 0%,rgba(var(--secondaryR),var(--secondaryG),var(--secondaryB),0) 100%);
  }
  .footer {
    font-size: x-small;
    width: 100%;
    padding-top: 20px;
    background: linear-gradient(0deg, rgba(19,19,22,1) 0%,rgba(19,19,22,0) 100%);
    p {
      padding-bottom: 8px;
      margin-bottom: 0;
      font-size: 11px;
    }
  }
  #router-content {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    overflow-x: hidden;
    > div {
      display: initial;
      > div {
        padding: 0px var(--gutters);
      }
    }
  }
  .card {
    background-color: var(--card-color);
  }
}

#nav {
  font-family: var(--headings);
  height: var(--nav-height);
  margin: 0;
  padding: 18px 0px 0px;
  transition: all 0.5s ease;

  .dropdown-menu {
    border-radius: 0px;
    border: 1px solid var(--tertiary-color);
    border-top: none;
    min-width: max-content;
    margin: 16px 0 0;
    padding: 0 0;
    height: 0px;
    display: flex;
    overflow: hidden;
    background: var(--bg-color);
    transition: border 0.5s ease;
    &.show {
      transition: height 0.5s;
      height: 32px;
    }
    > li {
      display: inline-block;
      transition: all 0.5s ease;
      .dropdown-item.active,
      .dropdown-item:active,
      .dropdown-item:hover {
        background-color: inherit;
        transition: all 0.5s ease;
      }
    }
  }

  a {
    position: relative;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0px;
    margin: 0 1rem 0;
    transition: 0.5s;
    font-weight: bolder;
    transition: all 0.5s ease;
    &.dropdown-item {
      padding: 0.25rem 0rem !important;
      margin: 0rem 1rem 0rem 1rem !important;
      font-size: 12px;
      text-align: right;
      display: block;
      width: fit-content;
      float: right;
      transition: all 0.5s ease;
    }
    // &::after {
    //   position: absolute;
    //   content: "";
    //   width: 100%;
    //   height: 6px;
    //   top: 100%;
    //   left: 0;
    //   background: rgba(255,255,255,1);
    //   transition: transform 0.5s;
    //   transform: scaleX(0);
    //   transform-origin: center;
    // }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: var(--hr-width);
      top: 20px;
      left: 0;
      background: var(--primary-color);
      transform: scaleX(0);
      transform-origin: center;
      transition: all 0.5s ease;
    }
    // &:hover::after {
    //   transform: scaleX(1);
    //   transform-origin: center;
    // }
    &:hover::before {
      transform: scaleX(1);
      transform-origin: center;
    }
    &.router-link-exact-active,
    &.router-link-active,
    &.router-link-active:hover {
      cursor: default;
      color: var(--primary-color);
      &::after,
      &::before {
        background: none;
      }
    }
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.175em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

#media-item-modal {
  .modal-header {
    padding: 0 10px;
    z-index: 100;
    background: none !important;
    border: none;
    color: white;
    font-variant: all-small-caps;
    font-weight: 100;
    font-family: var(--headings);
    letter-spacing: 1px;
    opacity: 0.5;
  }
  .modal-body {
    padding: 0;
    > span {
      color: white;
      opacity: 0.5;
      display: block;
      width: 100%;
      text-align: left;
      padding: 10px 16px;
    }
  }
  .modal-body,
  .modal-content {
    background: transparent !important;
    max-height: 100%;
  }
  .modal-footer {
    display: none;
  }
  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

div#media-item-modal___BV_modal_backdrop_ {
  opacity: 0.9;
}

.enforce-max-width {
  max-width: var(--max-width) !important;
  margin: 0 auto;
}
</style>
