<template lang="pug">
  .hello
    h1 {{ title }}
    p {{ subtitle }}
</template>

<script>
export default {
  name: "Index",
  props: {
    title: String,
    subtitle: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h1,
p {
  transition: all 0.5s ease;
  color: var(--light-home-color);
  font-weight: 500;
}

p {
  font-size: 12px;
}

.hello {
  margin: 0 auto;
  height: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 50%);
  h1 {
    white-space: nowrap;
  }
}
</style>
