<template lang="pug">
  .header
    #nav
      nav
        transition(name="slide-fade", appear)
          b-nav(align='center')
            b-nav-item(to='/', exact, exact-active-class='active') Home
            b-nav-item(to='/soundtrack', exact-active-class='active') Soundtrack
            b-nav-item(to='/about', exact-active-class='active') About
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      mode: "dark"
    };
  }
};
</script>
